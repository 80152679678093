import styled from "@emotion/styled";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import Avatar from "@mui/material/Avatar";
import MuiChip from "@mui/material/Chip";
import blue from "@mui/material/colors/blue";
import { FC } from "react";

import { Card, CardContent, UpDownDiv } from "@/utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./index.scss";

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  color: white;
  margin-bottom: 4px;

  span {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const TokenAccountCard: FC<{
  accountId: string;
  expiredOn: string;
  verifiedBy: string;
}> = ({ accountId, expiredOn, verifiedBy }) => {
  return (
    <Card>
      <CardContent>
        <div className="token-card" style={{ height: "108px" }}>
          <div className="token-card__header">
            <div className="token-card__logo">
              <Avatar sx={{ bgcolor: blue[500] }}>
                <CreditScoreOutlinedIcon />
              </Avatar>
            </div>
            <UpDownDiv
              header={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "5px" }}>Your Account</div>
                  <Chip
                    label="Verified"
                    color="success"
                    size="small"
                    sx={{ padding: "10px" }}
                  />
                </div>
              }
              footer={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "3px" }}>{accountId}</div>
                  <ContentCopyIcon style={{ height: "14px", width: "14px" }} />
                </div>
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // marginTop: '18px',
              position: "absolute",
              bottom: 0,
              marginBottom: "16px",
            }}
          >
            <div>
              <span style={{ color: "rgba(0,0,0,0.5)" }}>Expires on</span>
              <span
                style={{
                  fontWeight: 500,
                  marginLeft: "5px",
                  marginRight: "15px",
                }}
              >
                {expiredOn}
              </span>
            </div>
            <div>
              <span style={{ color: "rgba(0,0,0,0.5)" }}>Verified by</span>
              <span style={{ fontWeight: 500, marginLeft: "5px" }}>
                {verifiedBy}
              </span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default TokenAccountCard;
