import Box from "@mui/material/Box";
import { FC } from "react";
import "./index.css";

export const TickerViewer: FC<{ ticker: string }> = ({ ticker }) => {
  return (
    <Box className="viewer-container">
      <Box className="viewer-container__title">Your ticker symbol</Box>
      <Box
        className={`viewer-container__symbol ${
          ticker && "viewer-container__symbol--active"
        }`}
      >
        {ticker
          ? ticker.startsWith("ELSEO")
            ? ticker
            : `ELSEO${ticker}`
          : "Ticker symbol"}
      </Box>
    </Box>
  );
};
