import "@/assets/scss/App.scss";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { routes } from "./routes";

// const reactLogo = require("./../assets/img/react_logo.svg");
const queryClient = new QueryClient();

const AppInternal = () => {
  // React.useEffect(() => {
  //   async function run() {
  //     const signingManager = await BrowserExtensionSigningManager.create({
  //       appName: "ELSEWARE_TOKEN-STUDIO",
  //       extensionName: "elsewarewallet",
  //     });
  //     const sdk = await Polymesh.connect({
  //       // nodeUrl: "wss://mainnet-rpc.polymesh.network/",
  //       nodeUrl: "wss://testnet-rpc.polymesh.live/",
  //       signingManager,
  //     });
  //     console.log(
  //       "getAccountBalance",
  //       await sdk.accountManagement.getAccountBalance(),
  //     );
  //     console.log("getAssets", await sdk.assets.getAssets());

  //     // callback is called whenever the extension Accounts change
  //     signingManager.onAccountChange((newAccounts) => {
  //       console.log("newAccounts", newAccounts);
  //       // change SDK's signing account, reload the page, do whatever
  //     });

  //     // callback is called whenever the extension's selected network changes
  //     signingManager.onNetworkChange((newNetwork) => {
  //       // act accordingly
  //       console.log("newNetwork", newNetwork);
  //     });
  //   }
  //   run();
  // }, []);
  const content = useRoutes(routes);
  return (
    <QueryClientProvider client={queryClient}>{content}</QueryClientProvider>
  );
};
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1150,
      lg: 1620,
      xl: 2080,
    },
  },
});
const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppInternal />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
