import { IPortfolioRecord, PolymeshService } from "@/services/polymesh-service";
import { Add } from "@mui/icons-material";
import Button from "@mui/material/Button";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TextField from "@mui/material/TextField";
import { useState, KeyboardEvent, MouseEvent } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useReactive } from "@/hooks/helpers";
import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import PrettyCurrency from "../pretty-currency";
import InputAdornment from "@mui/material/InputAdornment";

const MoveBetweenPortfolioButton = ({
  from,
  asset,
}: {
  from: string;
  asset: string;
}) => {
  const [open, setOpen] = useState(false);
  const anchor = "left";
  const toggleDrawer =
    (open_: boolean) => (event: KeyboardEvent | MouseEvent) => {
      setOpen(open_);
    };
  const portfolios = useReactive(PolymeshService.$portfolios);

  const [to, setTo] = useState("");
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);

  const [portfolioDict, setPortfolioDict] = useState<{
    [_: string]: IPortfolioRecord;
  }>({});
  React.useEffect(() => {
    setPortfolioDict(
      portfolios.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}),
    );
  }, [portfolios]);

  const [available, setAvailable] = useState("0");

  React.useEffect(() => {
    const port = portfolioDict[from];
    if (!port) {
      return;
    }
    const info = port.assets.find((a) => a.assetHumanStr === asset);
    if (!info) {
      return;
    }
    setAvailable(info.balances.free.toString());
  }, [portfolioDict, from, asset]);

  return (
    <>
      <SwipeableDrawer
        anchor={anchor}
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box
          sx={{
            minWidth: "500px",
            width: "33vw",
            padding: "50px",
            boxSizing: "border-box",
          }}
        >
          <h1>Transfer </h1>
          <h3 style={{ textAlign: "center" }}>{asset}</h3>
          <h2>from</h2>
          <h3 style={{ textAlign: "center" }}>
            {portfolioDict[from]?.name}({portfolioDict[from]?.id})
          </h3>
          <h2>to</h2>

          <Box>
            <FormControl fullWidth>
              <InputLabel id="portfolio-select-label">Portfolio</InputLabel>
              <Select
                labelId="portfolio-select-label"
                id="portfolio-select"
                label="Portfolio"
                onChange={(e) => setTo(e.target.value as string)}
              >
                {portfolios.map((p) => (
                  <MenuItem value={p.id} key={p.id}>
                    {p.name}({p.id})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <h2>with</h2>
          <Box
            sx={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
          >
            <TextField
              required
              fullWidth
              id="amount"
              label="Amount"
              type="number"
              onChange={(e) => setAmount(e.target.value as unknown as number)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <span style={{ margin: "0 3px" }}>/</span>
                    <PrettyCurrency value={parseFloat(available)} currency="" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <h2 style={{ marginBottom: "50px" }}>unit(s)</h2>

          <Button
            disabled={!!!amount || !!!to}
            fullWidth
            variant="contained"
            onClick={async () => {
              try {
                setLoading(true);
                await PolymeshService.transferTokenToPortfolio(
                  from,
                  to,
                  asset,
                  `${amount}`,
                );
                setLoading(false);
              } catch (e) {
                console.error(e);
                setLoading(false);
              }
              setOpen(false);
              PolymeshService.getPortfolios();
            }}
          >
            {loading && (
              <CircularProgress
                size="14px"
                sx={{ color: "white", marginRight: "10px" }}
              />
            )}
            Move
          </Button>
        </Box>
      </SwipeableDrawer>
      <Button size="small" onClick={() => setOpen(true)}>
        Move
      </Button>
    </>
  );
};

export default MoveBetweenPortfolioButton;
