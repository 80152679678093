import TokenBalanceCard from "@/components/token-balance-card";
import { IPortfolioRecord, PolymeshService } from "@/services/polymesh-service";
import { debounce, Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";

export const PortfolioSearch = () => {
  const [addressToSearch, setAddressToSearch] = React.useState("");
  const [portfolios, setPortfolios] = React.useState<IPortfolioRecord[]>([]);

  const [isFetchingPortfolios, setIsFetchingPortfolios] = React.useState(false);

  const fetchPortfolios = React.useCallback(
    debounce(async (ownerId: string) => {
      if (!ownerId) {
        return;
      }
      setIsFetchingPortfolios(true);
      setPortfolios(
        await PolymeshService.searchPortfolioWithDetailForDid(ownerId),
      );
      setIsFetchingPortfolios(false);
    }, 500),
    [],
  );

  React.useEffect(() => {
    fetchPortfolios(addressToSearch);
  }, [addressToSearch]);

  return (
    <Box>
      <Box style={{ display: "flex", maxWidth: "800px", margin: "20px auto" }}>
        <TextField
          required
          fullWidth
          id="addressToSearch"
          label="Address To Search"
          onChange={(e) =>
            setAddressToSearch(e.target.value as unknown as string)
          }
        />
        <Button
          style={{ marginLeft: "25px", width: "100px", color: "white" }}
          size="small"
          variant="contained"
          disabled={!addressToSearch || isFetchingPortfolios}
          onClick={() => {
            fetchPortfolios(addressToSearch);
          }}
        >
          {isFetchingPortfolios ? (
            <CircularProgress size="20px" style={{ color: "white" }} />
          ) : (
            <SearchIcon />
          )}
        </Button>
      </Box>
      <Box style={{ padding: "0 120px" }}>
        {portfolios.map((port, idx) => {
          return (
            <Box key={`${idx}`}>
              <h2>
                {port.name} ({port.id})
              </h2>
              <Grid container spacing={2}>
                {port.assets.map((asset, assetIndex) => {
                  return (
                    <Grid
                      item
                      key={`${assetIndex}`}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={3}
                      onClick={() => {
                        asset.fetchDetailFunc();
                      }}
                    >
                      <Box style={{ minWidth: "450px", maxWidth: "450px" }}>
                        <TokenBalanceCard
                          tokenLogo={
                            <Box
                              style={{
                                background: "#274D8D",
                                color: "#F3A063",
                                padding: "10px",
                                width: "20px",
                                height: "20px",
                                textAlign: "center",
                                opacity: 0.8,
                                borderRadius: "50%",
                                fontWeight: 600,
                              }}
                            >
                              {asset.assetHumanStr.startsWith("ELSEO")
                                ? asset.assetHumanStr
                                    .split("-")[1][0]
                                    .substring(0, 3)
                                : asset.assetHumanStr[0]}
                            </Box>
                          }
                          tokenSymbol={asset.assetHumanStr}
                          balance={asset.balances?.total.toNumber() || 0}
                          available={asset.balances?.free.toNumber() || 0}
                          unAvailable={asset.balances?.locked.toNumber() || 0}
                        />
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
