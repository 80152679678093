import { PolymeshService } from "@/services/polymesh-service";
import { Add } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TextField from "@mui/material/TextField";
import { useState, KeyboardEvent, MouseEvent } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const CreateNewButton = () => {
  const [open, setOpen] = useState(false);
  const anchor = "left";
  const toggleDrawer =
    (open_: boolean) => (event: KeyboardEvent | MouseEvent) => {
      setOpen(open_);
    };
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  return (
    <>
      <SwipeableDrawer
        anchor={anchor}
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box
          sx={{
            minWidth: "500px",
            width: "33vw",
            padding: "50px",
            boxSizing: "border-box",
          }}
        >
          <h1>Create New Portfolio</h1>
          <Box sx={{ marginBottom: "20px" }}>
            <TextField
              required
              fullWidth
              id="portfolio-name"
              label="Portfolio Name"
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Button
            disabled={!!!name}
            fullWidth
            variant="contained"
            onClick={async () => {
              //   Poly
              try {
                setLoading(true);
                await PolymeshService.createPortfolio(name);
                setLoading(false);
              } catch (e) {
                console.error(e);
                setLoading(false);
              }
              setOpen(false);
              PolymeshService.getPortfolios();
            }}
          >
            {loading && (
              <CircularProgress
                size="14px"
                sx={{ color: "white", marginRight: "10px" }}
              />
            )}
            Create
          </Button>
        </Box>
      </SwipeableDrawer>
      <Button startIcon={<Add />} size="small" onClick={() => setOpen(true)}>
        Create New
      </Button>
    </>
  );
};

export default CreateNewButton;
