import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export const TokenDetail = () => {
  return (
    <Box>
      Security token details
      <Button>Edit token details</Button>
      <Box>
        <Box>Your ticker symbol</Box>
        <Box>ELSEOHLOWD</Box>
      </Box>
      <Box>
        <Box>icon</Box>
        <Box>
          <Box>Total supplu</Box>
          <Box>100000 ELSEOHLOWD</Box>
        </Box>
      </Box>
      <Box>
        {[
          {
            field: "Security token name",
            value: "ELSEOHLOWD",
          },
          {
            field: "Security type",
            value: "Equity Common",
          },
          {
            field: "Divisibility of token",
            value: "Equity Common",
          },
        ].map(({ field, value }) => {
          return (
            <Box key={field}>
              <Box>{field}</Box>
              <Box>{value}</Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
