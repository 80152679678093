import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TickerViewer } from "@/components/TickerViewer";
import { FC, useEffect, useState } from "react";
import "./index.css";
import {
  debounce,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { PolymeshService } from "@/services/polymesh-service";
import {
  CreateAssetParams,
  KnownAssetType,
} from "@polymeshassociation/polymesh-sdk/types";
import { BigNumber } from "@polymeshassociation/polymesh-sdk";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

const steps = ["Reserve ticker symbol", "Configure token"];

const TokenInput: FC<{
  value: string;
  valid: boolean;
  onChange?: (v: string) => void;
}> = ({ valid, value, onChange }) => {
  return (
    <Box>
      <Box className="token-input-label">Security token ticker symbol</Box>
      <Box className="token-input-container">
        <input
          className="token-input"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </Box>
      {!valid && value && (
        <Box className="token-input-error">
          Ticker symbol is not available. Please try another ticker symbol.
        </Box>
      )}
    </Box>
  );
};

const StepReserveTickerSymbol = ({
  ticker,
  setTicker,
  onNext,
}: {
  ticker: string;
  setTicker: React.Dispatch<React.SetStateAction<string>>;
  onNext: () => void;
}) => {
  const [tickerValid, setTickerValid] = useState(false);
  const [tickerInternal, setTickerInternal] = useState("");

  const [loading, setLoading] = useState(false);

  const verifyTicker = React.useCallback(
    debounce(async (tk: string) => {
      if (tk && tk.length > 1 && tk.length < 12) {
        const ret = await PolymeshService.validTicker(tk);
        console.log("ret", ret);
        setTickerValid(ret);
      }
    }, 1000),
    [],
  );

  useEffect(() => {
    verifyTicker(ticker);
  }, [ticker]);

  useEffect(() => {
    if (tickerInternal) {
      setTicker(`ELSEO${tickerInternal}`.toUpperCase());
    } else {
      setTicker("");
    }
  }, [tickerInternal]);

  return (
    <Box>
      <Box>
        <TokenInput
          value={tickerInternal}
          onChange={setTickerInternal}
          valid={tickerValid}
        />
      </Box>
      <Box style={{ margin: "20px 0" }}>
        <TickerViewer ticker={ticker} />
      </Box>
      <Box>
        <Button
          variant="contained"
          disabled={!tickerValid || loading}
          onClick={async () => {
            setLoading(true);
            await PolymeshService.reserveTicker(ticker);
            setLoading(false);
            onNext();
          }}
        >
          {loading && <CircularProgress size="14px" />}{" "}
          <span style={{ marginLeft: "10px" }}>Reserve ticker symbol</span>
        </Button>
      </Box>
      <Box className="ticker-symbol-hint">
        Ticker symbol reservation fee
        <span style={{ color: "black" }}> 500 POLYX</span>
      </Box>
    </Box>
  );
};

type IKeysOfCreateAssetParams = keyof CreateAssetParams;
const StepConfigureTickerSymbol = ({
  ticker,
  onNext,
}: {
  ticker: string;
  onNext: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  const [createTokenParams, setCreateTokenParams] = useState<CreateAssetParams>(
    {
      name: "",
      isDivisible: false,
      assetType: KnownAssetType.EquityCommon,
      requireInvestorUniqueness: false,
      initialSupply: new BigNumber("100000"),
    },
  );

  const updateKV = <T extends IKeysOfCreateAssetParams>(
    k: T,
    v: CreateAssetParams[T],
  ) => {
    setCreateTokenParams((o) => ({
      ...o,
      [k]: v,
    }));
  };

  return (
    <Box>
      {/* <Box>Security token details</Box> */}
      <Box className="form-box-row">
        <TickerViewer ticker={ticker} />
      </Box>
      <Box className="form-box-row">
        <TextField
          id="outlined-required"
          label="Security token name"
          defaultValue={createTokenParams.name}
          onChange={(e) => updateKV("name", e.target.value)}
          fullWidth
        />
      </Box>
      <Box className="form-box-row">
        <FormControl fullWidth>
          <InputLabel id="instrument-type-label">Instrument Type</InputLabel>
          <Select
            labelId="instrument-type-label"
            id="instrument-type"
            defaultValue={createTokenParams.assetType}
            label="Instrument Type"
            onChange={(e) => updateKV("assetType", e.target.value)}
          >
            {Object.keys(KnownAssetType).map((k) => (
              <MenuItem key={k} value={KnownAssetType[k]}>
                {KnownAssetType[k]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box className="form-box-row">
        <Box>Require Investor Uniqueness Claim</Box>
        <Switch
          defaultChecked={createTokenParams.requireInvestorUniqueness}
          onChange={(e) =>
            updateKV("requireInvestorUniqueness", e.target.checked)
          }
        />
      </Box>
      {/* <Box className="form-box-row">
        <Box style={{ marginBottom: "15px" }}>
          Security identifier (optional)
        </Box>
        <Box style={{ display: "flex" }}>
          <Box style={{ width: "120px", marginRight: "15px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Identifier</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={10}
                label="Age"
                onChange={null}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TextField
            id="outlined-required"
            label="Security token name"
            defaultValue="Hello World"
            fullWidth
          />
        </Box>
      </Box> */}
      <Box className="form-box-row">
        <Box style={{ marginBottom: "15px" }}>Initial Supply</Box>
        <TextField
          id="intial-supply"
          label="Mint"
          defaultValue={createTokenParams.initialSupply.toNumber()}
          fullWidth
          onChange={(e) =>
            updateKV("initialSupply", new BigNumber(`${e.target.value}`))
          }
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          onClick={async () => {
            console.log("createTokenParams", createTokenParams);
            setLoading(true);
            await PolymeshService.createToken({ ...createTokenParams, ticker });
            setLoading(false);
            onNext();
          }}
          disabled={loading}
        >
          {loading && <CircularProgress size="14px" />} Configure Security Token
        </Button>
      </Box>
      <Box className="ticker-symbol-hint">
        Token configuration fee{" "}
        <span style={{ color: "black" }}>2,500 POLYX</span>
      </Box>
    </Box>
  );
};

export default function TokenFactory() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [ticker, setTicker] = useState("");
  const navigate = useNavigate();

  return (
    <Box sx={{ width: "100%", padding: "60px 124px", boxSizing: "border-box" }}>
      <Box sx={{ width: "500px", marginBottom: "50px" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Box sx={{ maxWidth: "600px" }}>
        {(() => {
          if (activeStep === 0) {
            return (
              <StepReserveTickerSymbol
                ticker={ticker}
                setTicker={setTicker}
                onNext={() => setActiveStep(1)}
              />
            );
          }

          if (activeStep === 1) {
            return (
              <StepConfigureTickerSymbol
                ticker={ticker}
                onNext={() => {
                  navigate("/main/overview");
                }}
              />
            );
          }

          return null;
        })()}
      </Box>
    </Box>
  );
}
