import { useReactive } from "@/hooks/helpers";
import { PolymeshService } from "@/services/polymesh-service";
import Box from "@mui/material/Box";
import {
  matchRoutes,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Stack from "@mui/material/Stack";
import SnackbarContent from "@mui/material/SnackbarContent";
import "./index.css";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Logo from "@/assets/logo.png";
import { formatAddress } from "@/utils";
import { useCallback, useEffect, useState } from "react";
import { routes } from "@/routes";
import Button from "@mui/material/Button";
import AddressViewer from "@/components/AddressViewer";
import { TransactionStatus } from "@polymeshassociation/polymesh-sdk/types";
import Chip from "@mui/material/Chip";
export const Header = ({
  showTransaction,
  setShowTransaction,
}: {
  showTransaction: boolean;
  setShowTransaction: (v: boolean) => void;
}) => {
  const balance = useReactive(PolymeshService.$balance);
  const account = useReactive(PolymeshService.$account);
  const identity = useReactive(PolymeshService.$identity);
  const assets = useReactive(PolymeshService.$assets);
  const portfolios = useReactive(PolymeshService.$portfolios);
  const appReady = useReactive(PolymeshService.$ready);

  const navigate = useNavigate();
  const reactiveLocation = useLocation();
  const [activePathDict, setActivePathDict] = useState<{
    [_: string]: boolean;
  }>({});

  useEffect(() => {
    const matchedRoutes = matchRoutes(routes, reactiveLocation);
    if (matchedRoutes !== null) {
      setActivePathDict(
        matchedRoutes.reduce(
          (prev, curr) => ({ ...prev, [curr.pathname]: true }),
          {},
        ),
      );
    }
  }, [reactiveLocation]);

  console.log("activePathDict", activePathDict);

  const isActive = useCallback(
    (p: string) => {
      return activePathDict[p];
    },
    [activePathDict],
  );

  const navItemList = [
    { title: "Overview", pathname: "/main/overview" },
    { title: "Transactions", pathname: "/main/transaction" },
    { title: "Token Factory", pathname: "/main/token-factory" },
    { title: "Contract", pathname: "/main/contract" },
    { title: "Venue", pathname: "/main/venue" },
    { title: "Distribution", pathname: "/main/distribution" },
    { title: "Search", pathname: "/main/search" },
    {
      title: "Pending Instruction List",
      pathname: "/main/pending-instruction-list",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#274D8D",
        padding: "20px 20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box style={{ marginRight: "100px" }}>
          <img src={Logo} style={{ height: "40px", width: "auto" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {navItemList.map(({ title, pathname }) => {
            return (
              <Box
                className={`menu ${isActive(pathname) ? "menu--active" : ""}`}
                key={pathname}
                onClick={() => navigate(pathname)}
              >
                {title}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ color: "white", fontWeight: 600, marginRight: "25px" }}>
          {formatAddress(identity?.did || "-")}
        </Box>
        <Box sx={{ color: "white", fontWeight: 600, marginRight: "50px" }}>
          {balance?.total.toFormat(2)} POLYX
        </Box>
        <Box>
          <ReceiptIcon
            onClick={() => {
              setShowTransaction(!showTransaction);
            }}
            sx={{
              color: showTransaction ? "#f3a063" : "white",
              cursor: "pointer",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const StatusChip = ({ status }: { status: TransactionStatus }) => {
  switch (status) {
    case TransactionStatus.Failed:
      return <Chip label={status} color="error" size="small" />;
    case TransactionStatus.Succeeded:
      return <Chip label={status} color="success" size="small" />;
    default:
      return <Chip label={status} color="primary" size="small" />;
  }
};

export const MainLayout = () => {
  const ready = useReactive(PolymeshService.$ready);
  const transactions = useReactive(PolymeshService.$transactions);
  const [showTransaction, setShowTransaction] = useState(true);

  if (!ready) {
    return (
      <Box
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: "translate(-50%,-50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  const transactionToVis = (transactions || []).filter(
    ({ address }) => !!address,
  );
  return (
    <Box style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Stack
        spacing={2}
        sx={{
          zIndex: 999999,
          maxWidth: 600,
          position: "fixed",
          right: "20px",
          top: "100px",
          backrgound: "white",
          ...(!showTransaction && { display: "none" }),
        }}
      >
        {transactionToVis.map((_, idx) => {
          const { address, content, status } =
            transactionToVis[transactionToVis.length - 1 - idx];
          return (
            <SnackbarContent
              key={`${idx}`}
              style={{ zIndex: 999999 }}
              message={
                <Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: " center",
                    }}
                  >
                    <Box sx={{ marginRight: "10px" }}>
                      <StatusChip status={status} />
                    </Box>
                    <AddressViewer address={address} addressType="extrinsic" />
                  </Box>
                  <Box>
                    <span style={{ fontWeight: 800 }}>{content}</span>
                  </Box>
                </Box>
              }
              sx={{ background: "white", color: "#274D8D", zIndex: 99999999 }}
            />
          );
        })}
      </Stack>
      <Box>
        <Header
          showTransaction={showTransaction}
          setShowTransaction={setShowTransaction}
        />
      </Box>
      <Box style={{ flexGrow: 1, overflow: "auto" }}>
        <Outlet />
      </Box>
    </Box>
  );
};
