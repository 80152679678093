import AddressViewer from "@/components/AddressViewer";
import { useReactive } from "@/hooks/helpers";
import { IPendingVenue, PolymeshService } from "@/services/polymesh-service";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

function Row(props: { row: IPendingVenue }) {
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="left">{row.id}</TableCell>
        <TableCell component="th" scope="row">
          <AddressViewer address={row.ownerIdentityId} />
        </TableCell>
        <TableCell align="left">{row.type}</TableCell>
        <TableCell align="left">{row.description}</TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Params
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(row.params || []).map((param) => (
                    <TableRow key={param.name}>
                      <TableCell component="th" scope="row">
                        {param.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {param.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}
export const Venue = () => {
  const pendingVenues = useReactive(PolymeshService.$venues);
  const isSyncingVenues = useReactive(PolymeshService.$isSyncingVenues);
  React.useEffect(() => {
    PolymeshService.syncVenueList();
  }, []);
  return (
    <Box style={{ maxWidth: "800px", margin: "20px auto" }}>
      <Button
        disabled={isSyncingVenues}
        sx={{ width: "120px" }}
        size="small"
        variant="contained"
        onClick={async () => {
          await PolymeshService.syncVenueList();
        }}
      >
        {isSyncingVenues && (
          <CircularProgress
            size="14px"
            sx={{ color: "white", marginRight: "10px" }}
          />
        )}
        Refresh
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Venue Id</TableCell>
              <TableCell align="left">From owner</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(pendingVenues || []).map((row, idx) => (
              <Row key={`${idx}`} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
