import { PolymeshService } from "@/services/polymesh-service";
import { debounce, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { BigNumber } from "@polymeshassociation/polymesh-sdk";
import { Instruction, Venue } from "@polymeshassociation/polymesh-sdk/types";
import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";

export const Distribution = () => {
  const [fromIdentityId, setFromIdentityId] = React.useState("");
  const [venueId, setVenueId] = React.useState("");
  const [instructionId, setInstructionId] = React.useState("");
  const [venueList, setVenueList] = React.useState<Venue[]>([]);

  const [isFetchingVenueList, setIsFetchingVenueList] = React.useState(false);

  const [theVenue, setTheVenue] = React.useState<Venue>();
  const [theInstruction, setTheInstruction] = React.useState<Instruction>();
  const [pendingInstructionList, setPendingInstructionList] = React.useState<
    Instruction[]
  >([]);
  const fetchVenueList = React.useCallback(
    debounce(async (ownerId: string) => {
      setIsFetchingVenueList(true);
      setVenueList(await PolymeshService.searchVenueForOwnerId(ownerId));
      setIsFetchingVenueList(false);
    }, 500),
    [],
  );

  const updateVenue = React.useCallback(
    debounce(async (venueList: Venue[], _venueId: string) => {
      const newTheVenue = venueList.find((venue: Venue) => {
        return venue.id.isEqualTo(new BigNumber(_venueId));
      });
      console.log("newTheVenue", newTheVenue);
      if (newTheVenue) {
        const instructions = await newTheVenue.getInstructions();
        console.log("instructions", instructions);
        setTheVenue(newTheVenue);
        setPendingInstructionList(instructions.pending);
      }
    }, 500),
    [],
  );

  React.useEffect(() => {
    updateVenue(venueList, venueId);
  }, [venueList, venueId]);

  React.useEffect(() => {
    setTheInstruction(
      pendingInstructionList.find((ins) => ins.id.isEqualTo(instructionId)),
    );
  }, [theVenue, instructionId, pendingInstructionList]);

  return (
    <Box
      style={{ maxWidth: "800px", margin: "20px auto" }}
      sx={{ "& > div": { marginTop: "20px" } }}
    >
      <Box style={{ display: "flex" }}>
        <TextField
          required
          fullWidth
          id="ownerId"
          label="Owner Identity Id"
          onChange={(e) =>
            setFromIdentityId(e.target.value as unknown as string)
          }
        />
        <Button
          style={{ marginLeft: "25px", width: "100px" }}
          size="small"
          variant="contained"
          disabled={!fromIdentityId || isFetchingVenueList}
          onClick={() => {
            fetchVenueList(fromIdentityId);
          }}
        >
          {isFetchingVenueList ? (
            <CircularProgress
              size="14px"
              sx={{ color: "white", marginRight: "10px" }}
            />
          ) : (
            <SearchIcon />
          )}
        </Button>
      </Box>

      <Box>
        <FormControl fullWidth>
          <InputLabel id="venue-select-label">Venue ID</InputLabel>
          <Select
            required
            labelId="venue-select-label"
            id="venue-select"
            label="Venue ID"
            value={venueId}
            onChange={(e) => setVenueId(e.target.value as string)}
          >
            {venueList.map((p) => (
              <MenuItem value={`${p.id}`} key={`${p.id}`}>
                {`${p.id}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box>
        <FormControl fullWidth>
          <InputLabel id="instruction-select-label">Instruction ID</InputLabel>
          <Select
            required
            labelId="instruction-select-label"
            id="instruction-select"
            label="instruction"
            value={instructionId}
            onChange={(e) => setInstructionId(e.target.value as string)}
          >
            {pendingInstructionList.map((p) => (
              <MenuItem value={`${p.id}`} key={`${p.id}`}>
                {`${p.id}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box>
        <Button
          size="small"
          variant="contained"
          style={{ width: "100%" }}
          disabled={!fromIdentityId || !instructionId || !venueId}
          onClick={async () => {
            // fetchVenueList(fromIdentityId);
            // confirm
            PolymeshService.runTransaction(await theInstruction.affirm());
          }}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );
};
