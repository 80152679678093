import { BrowserExtensionSigningManager } from "@/components/browser-extension-signing-manager";
import {
  Api as PortfolioAPI,
  V1Instruction,
} from "@/third_party/elseware-polymesh-api/portfolio/Api";
import { BigNumber, Polymesh } from "@polymeshassociation/polymesh-sdk";
import { AccountManagement } from "@polymeshassociation/polymesh-sdk/api/client/AccountManagement";
import { Issuance } from "@polymeshassociation/polymesh-sdk/api/entities/Asset/Issuance";
import { Portfolio } from "@polymeshassociation/polymesh-sdk/api/entities/Portfolio";
import { Context } from "@polymeshassociation/polymesh-sdk/internal";

import {
  Account,
  Asset,
  Balance,
  CreateAssetParams,
  DefaultPortfolio,
  GenericPolymeshTransaction,
  Identity,
  Instruction,
  NumberedPortfolio,
  Order,
  PortfolioBalance,
  TransactionOrderFields,
  TransactionStatus,
  Venue,
  VenueType,
} from "@polymeshassociation/polymesh-sdk/types";
import { ref, Ref } from "@vue/reactivity";

export interface ITransactionHistoryRecord {
  address: string;
  blockHash: string;
  blockNumber: string;
  extrinsicHash: string;
  extrinsicIdx: string;
  nonce: string;
  params: { name: string; value: string }[];
  specVersionId: string;
  txTag: string;
}

export interface IPendingVenue {
  id: string;
  type: VenueType;
  ownerIdentityId: string;
  description: string;
}

export interface IAssetInfo {
  assetDid: string;
  assetHumanStr: string;
  balances: Balance;
  fetchDetailFunc: () => void;
}

export interface IPortfolioRecord {
  id: string;
  humanStr: string;
  name: string;
  assets: IAssetInfo[];
}

export type IPendingInstruction = V1Instruction & {
  portfolioId: string;
  portfolioName: string;
};

export interface ITransferItem {
  assetId: string;
  amount: string;
  fromIdentityId: string;
  fromPortfolioId: string;
  toIdentityId: string;
  toPortfolioId: string;
  description: string;
}

export interface IContractItem {
  ticker: string;
  amount: string;
  fromPortfolio: DefaultPortfolio | NumberedPortfolio;
  toPortfolio: DefaultPortfolio | NumberedPortfolio;
}

function formatBalances(balances: PortfolioBalance[]): IAssetInfo[] {
  return balances
    .map((b) => ({
      assetDid: b.asset.did,
      assetHumanStr: b.asset.ticker,
      balances: {
        free: b.free,
        locked: b.locked,
        total: b.total,
      },
      fetchDetailFunc: async () => {
        // const operationHistory = await b.asset.getOperationHistory();
        // const { did: assetDid } = b.asset;
        // const createdAt = await b.asset.createdAt();
        // if (!createdAt) {
        //   return;
        // }
        // this.$assetExtraInfo.value = {
        //   ...this.$assetExtraInfo.value,
        //   [assetDid]: {
        //     ...this.$assetExtraInfo.value[assetDid],
        //     // operationHistory: operationHistory.map((op) => ({
        //     //   ...op.identity,
        //     //   ...op.history,
        //     // })),
        //     createdAt: {
        //       blockHash: createdAt.blockHash,
        //       blockDate: createdAt.blockDate,
        //       blockNumber: createdAt.blockNumber.toString(),
        //     },
        //   },
        // };
      },
    }))
    .reduce((p, c) => [...p, c], []);
}

export class PolymeshServiceImpl {
  $ready: Ref<boolean> = ref(false);
  sdk?: Polymesh;
  nodeUrl: string;
  appName: string;
  extensionName: string;
  $balance: Ref<Balance | null> = ref(null);
  $assets: Ref<
    {
      did: string;
      ticker: string;
      humanStr: string;
    }[]
  > = ref([]);
  assets: Asset[] = [];

  $account: Ref<{ address: string; key: string } | null> = ref(null);
  account: Account = ref(null);

  $identity: Ref<{ did: string } | null> = ref(null);
  identity: Identity | null = null;

  $isSyncingVenues: Ref<boolean> = ref(false);
  $venues: Ref<IPendingVenue[]> = ref([]);

  $isSyncingPendingInstructionList: Ref<boolean> = ref(false);
  $pendingInstructionList: Ref<IPendingInstruction[]> = ref([]);

  $assetExtraInfo: Ref<{
    [did: string]: {
      createdAt: {
        blockHash: string;
        blockNumber: string;
        blockblockDate: Date;
      };
    } & any;
  }> = ref({});

  $isLoadingPortfolios: Ref<boolean> = ref(false);
  $portfolios: Ref<IPortfolioRecord[]> = ref([]);

  $isCheckingContractValid: Ref<boolean> = ref(false);
  $contractValidStatus: Ref<{ result: boolean; reason: string }[]> = ref([]);

  $transactions: Ref<
    {
      address: string;
      content: string;
      status: TransactionStatus;
    }[]
  > = ref([]);

  transactionHistoryNext = 0;
  transactionHistoryTotal: number | undefined = undefined;

  $isSyncingransactionHistory: Ref<boolean> = ref(false);
  $transactionHistory: Ref<ITransactionHistoryRecord[]> = ref([]);

  constructor({
    appName,
    extensionName,
    nodeUrl,
  }: {
    appName?: string;
    extensionName?: string;
    nodeUrl?: string;
  }) {
    this.nodeUrl = nodeUrl;
    this.appName = appName;
    this.extensionName = extensionName;
  }

  async init() {
    console.log("init....");
    const signingManager = await BrowserExtensionSigningManager.create({
      appName: this.appName,
      extensionName: this.extensionName,
    });
    // this.extensionName = globalObject.extension;
    this.sdk = await Polymesh.connect({
      nodeUrl: this.nodeUrl,
      signingManager,
    });
    await this.getIdentity();
    await Promise.all([
      this.getBasicInfo(),
      this.getAssetList(),
      this.getAccount(),
    ]);
    this.$ready.value = true;
  }

  async getAccountAndIdentityByAddress(address: string) {
    const account = await this.sdk?.accountManagement.getAccount({ address });
    const identity = await account.getIdentity();
    return { account, identity };
  }

  async getIdentityIdByAddress(address: string) {
    const account = await this.sdk?.accountManagement.getAccount({ address });
    const identity = await account.getIdentity();
    return identity.did;
  }

  async ensureStrAsIdentityId(identityIdOrAddress: string) {
    if (identityIdOrAddress.startsWith("0x")) {
      return identityIdOrAddress;
    }
    return await this.getIdentityIdByAddress(identityIdOrAddress);
  }

  async runTransaction(
    tx: GenericPolymeshTransaction<any, any>,
    title?: string,
  ) {
    try {
      console.log("tx", tx);
      this.$transactions.value.push({
        content: title,
        address: tx.txHash || "",
        status: tx.status,
      });

      const idx = this.$transactions.value.length - 1;

      tx.onStatusChange((v) => {
        console.log("idx", idx, v, "v");
        if (v.txHash) {
          this.$transactions.value[idx].address = v.txHash;
        }
        this.$transactions.value[idx].status = v.status;
      });
    } catch (e) {
      console.error(e);
    }
    return await tx.run();
  }

  async createPortfolio(name: string) {
    const queue = await this.sdk?.identities.createPortfolio({ name });
    return this.runTransaction(queue, `Create Portfolio With Name: [${name}]`);
  }

  async syncTransactionHistory() {
    this.$isSyncingransactionHistory.value = true;
    try {
      const history = await this.account.getTransactionHistory({
        orderBy: {
          field: TransactionOrderFields.BlockId,
          order: Order.Desc,
        },
      });

      console.log("history", history);
      this.transactionHistoryTotal =
        (history.count && history.count.toNumber()) || undefined;
      this.$transactionHistory.value = history.data
        .map((v) => {
          try {
            return {
              address: v.address,
              blockHash: v.blockHash,
              blockNumber: v.blockNumber.toString(),
              extrinsicHash: v.extrinsicHash,
              extrinsicIdx: v.extrinsicIdx.toString(),
              nonce: v.nonce && v.nonce.toString(),
              params: v.params.map(({ name, value }) => ({
                name: name as string,
                value: value && JSON.stringify(value),
              })),
              specVersionId: v.specVersionId.toString(),
              txTag: v.txTag,
            };
          } catch (e) {
            console.error(e);
            return undefined;
          }
        })
        .filter((v) => v !== undefined);
      this.$isSyncingransactionHistory.value = false;
    } catch (e) {
      console.error(e);
      this.$isSyncingransactionHistory.value = false;
    }
  }

  async getIdentity() {
    this.identity = await this.sdk?.getSigningIdentity();
    console.log("identity", this.identity);
    this.$identity.value = { did: this.identity.did };
  }

  async getPortfolios() {
    if (!this.identity) {
      throw Error("not ok");
    }
    if (this.$isLoadingPortfolios.value) {
      return;
    }

    this.$isLoadingPortfolios.value = true;
    try {
      const portfolios = await this.identity.portfolios.getPortfolios();
      this.$portfolios.value = await Promise.all(
        portfolios.map(async (p) => {
          const balances = await p.getAssetBalances();
          return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            id: (p.id && p.id.toString()) || "DEFAULT",
            humanStr: p.toHuman().did,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //   @ts-ignore
            name: p.getName
              ? await (p as NumberedPortfolio).getName()
              : "Unassigned",
            assets: formatBalances(balances),
          };
        }),
      );
      this.$isLoadingPortfolios.value = false;
    } catch (e) {
      this.$isLoadingPortfolios.value = false;
      console.error(e);
      return;
    }
  }

  async getAccount() {
    this.account = await this.sdk?.accountManagement.getSigningAccount();
    console.log("account", this.account);
    this.$account.value = {
      address: this.account.address,
      key: this.account.key,
    };
  }
  async getBasicInfo() {
    this.$balance.value = await this.sdk?.accountManagement.getAccountBalance();
    console.log("balance", this.$balance.value);
  }

  async getAssetList() {
    this.assets = await this.sdk?.assets.getAssets();
    this.$assets.value = this.assets.map((v) => ({
      ticker: v.ticker,
      did: v.did,
      humanStr: v.toHuman(),
    }));
    console.log("assets", this.$assets.value);
  }

  async validTicker(ticker: string) {
    if (!this.sdk) {
      throw Error("sdk not ready");
    }
    const ret = await this.sdk?.assets.isTickerAvailable({ ticker });
    console.log("ret", ticker, ret);
    return ret === false ? false : true;
  }

  async reserveTicker(ticker: string) {
    if (!this.sdk) {
      throw Error("sdk not ready");
    }
    const queue = await this.sdk?.assets.reserveTicker({ ticker });
    queue.onStatusChange((v) => {
      console.log("onStatusChangev", v);
    });
    console.log("createTicker", ticker, queue);
    return this.runTransaction(queue, `Reserve Ticker: [${ticker}]`);
  }

  async transferTokenToPortfolio(
    fromPortfolioId: string,
    toPortfolioId: string,
    asset: string,
    amount: string,
  ) {
    const [fromPortfolio, toPortfolio] = await Promise.all([
      fromPortfolioId === "DEFAULT"
        ? this.identity.portfolios.getPortfolio()
        : this.identity.portfolios.getPortfolio({
            portfolioId: new BigNumber(fromPortfolioId),
          }),
      toPortfolioId === "DEFAULT"
        ? this.identity.portfolios.getPortfolio()
        : this.identity.portfolios.getPortfolio({
            portfolioId: new BigNumber(toPortfolioId),
          }),
    ]);

    const moveQueue = await fromPortfolio.moveFunds({
      items: [
        {
          asset: asset,
          amount: new BigNumber(amount),
        },
      ],
      to: toPortfolio,
    });
    return this.runTransaction(
      moveQueue,
      `Move  [${amount}] [${asset}] from [${fromPortfolioId}] t0 [${toPortfolioId}]`,
    );
  }

  async createToken({
    ticker,
    ...createParams
  }: { ticker: string } & CreateAssetParams) {
    const reservationDetail = await this.sdk?.assets.getTickerReservation({
      ticker,
    });
    const creationQueue = await reservationDetail.createAsset({
      ...createParams,
    });
    console.log("creationQueue", ticker, creationQueue);
    creationQueue.onStatusChange((v) => {
      console.log("onStatusChangev", v);
    });
    return this.runTransaction(
      creationQueue,
      `Create Token for Ticker: [${ticker}]`,
    );
  }

  async searchPortfolioWithDetailForDid(identityId: string) {
    console.log("search for", identityId);
    try {
      const targetIdentity: Identity = await this.sdk.identities.getIdentity({
        did: identityId,
      });
      console.log("get did", targetIdentity);

      const [defaultPortfolio, ...numberPortfolios] =
        await targetIdentity.portfolios.getPortfolios();

      return [
        {
          id: "DEFAULT",
          humanStr: defaultPortfolio.toHuman().did,
          name: "DEFAULT",
          assets: formatBalances(await defaultPortfolio.getAssetBalances()),
        },
        ...(await Promise.all(
          numberPortfolios.map(async (p) => {
            const balances = await p.getAssetBalances();
            return {
              id: (p.id && p.id.toString()) || "DEFAULT",
              humanStr: p.toHuman().did,
              name: await p.getName(),
              assets: formatBalances(balances),
            };
          }),
        )),
      ];
    } catch (e) {
      console.error("error", e);
      return [];
    }
  }

  async getPortolioByIdentityIdAndId(identityId: string, portfolioId: string) {
    console.log("getPortolioByIdentityIdAndId", identityId, portfolioId);
    const targetIdentity: Identity = await this.sdk.identities.getIdentity({
      did: identityId,
    });
    const [defaultPortfolio, ...customPortfolioList] =
      await targetIdentity.portfolios.getPortfolios();
    if (portfolioId === "DEFAULT") {
      return defaultPortfolio;
    }
    return customPortfolioList.find((v) => v.id.toString() === portfolioId);
  }

  async searchPortfolioForDid(identityId: string, containAsset = false) {
    console.log("search for", identityId);
    try {
      const targetIdentity: Identity = await this.sdk.identities.getIdentity({
        did: identityId,
      });
      console.log("get did", targetIdentity);

      const [defaultPortfolio, ...numberPortfolios] =
        await targetIdentity.portfolios.getPortfolios();

      const rawResult = [
        {
          id: "DEFAULT",
          humanStr: defaultPortfolio.toHuman().did,
          name: "DEFAULT",
          assets: containAsset
            ? formatBalances(await defaultPortfolio.getAssetBalances())
            : [],
        },
        ...(await Promise.all(
          numberPortfolios.map(async (p) => {
            return {
              id: (p.id && p.id.toString()) || "DEFAULT",
              humanStr: p.toHuman().did,
              name: await p.getName(),
              assets: containAsset
                ? formatBalances(await p.getAssetBalances())
                : [],
            };
          }),
        )),
      ];

      return rawResult.map((v) => ({
        ...v,
        assetDict: v.assets.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.assetHumanStr]: curr,
          }),
          {} as { [_: string]: IAssetInfo },
        ),
      }));
    } catch (e) {
      console.error("error", e);
      return [];
    }
  }

  async revokeAsset(parameters: {
    ticker: string;
    amount: string;
    fromIdentityId: string;
    fromPortfolioId: string;
  }) {
    const queue = await (
      await this.sdk.assets.getAsset({ ticker: parameters.ticker })
    ).controllerTransfer({
      originPortfolio: await this.getPortolioByIdentityIdAndId(
        parameters.fromIdentityId,
        parameters.fromPortfolioId,
      ),
      amount: new BigNumber(parameters.amount),
    });
    return await this.runTransaction(
      queue,
      `Revoking ticker from [${parameters.fromIdentityId}]`,
    );
  }

  // async getPortfolioDetail() {

  // }

  async createContract(parameters: {
    description: string;
    contractItemList: IContractItem[];
  }) {
    const createVenueTX = await this.sdk.settlements.createVenue({
      type: VenueType.Other,
      description: parameters.description,
    });
    const transferVenue: Venue = await await this.runTransaction(
      createVenueTX,
      "Create Contract Venue",
    );

    const legs = await Promise.all(
      parameters.contractItemList.map(async (v) => {
        return {
          asset: v.ticker,
          amount: new BigNumber(v.amount),
          from: v.fromPortfolio,
          to: v.toPortfolio,
        };
      }),
    );

    const queue = await transferVenue.addInstruction({ legs });
    return await this.runTransaction(
      queue,
      `Create a contract with [${parameters}]`,
    );
  }

  async tranferAsset(parameters: ITransferItem) {
    console.log("transferAsset", parameters);

    const createVenueTX = await this.sdk.settlements.createVenue({
      type: VenueType.Other,
      description: parameters.description,
    });
    const transferVenue: Venue = await await this.runTransaction(
      createVenueTX,
      "Create Transfer Venue",
    );
    const fromPortfolio = await this.getPortolioByIdentityIdAndId(
      parameters.fromIdentityId,
      parameters.fromPortfolioId,
    );
    const toPortfolio = await this.getPortolioByIdentityIdAndId(
      parameters.toIdentityId,
      parameters.toPortfolioId,
    );
    const legs = [
      {
        asset: parameters.assetId,
        amount: new BigNumber(parameters.amount),
        from: fromPortfolio,
        to: toPortfolio,
      },
    ];
    console.log("legs", legs);
    const queue = await transferVenue.addInstruction({ legs });
    return await this.runTransaction(
      queue,
      `Transfer [${parameters.amount}] [${parameters.assetId}] to [${parameters.toPortfolioId}]`,
    );
  }

  async distributeToken(parameters: {
    fromPortfolioId: string;
    ticker: string;
    toIdentityId: string;
    toPortfolioId: string;
    amount: string;
    description: string;
  }) {
    console.log("distributeToken", parameters);
    const {
      fromPortfolioId,
      ticker,
      toIdentityId,
      toPortfolioId,
      amount,
      description,
    } = parameters;
    const distributionVenueTransaction = await this.sdk.settlements.createVenue(
      {
        type: VenueType.Distribution,
        description,
      },
    );

    const distributionVenue: Venue = await await this.runTransaction(
      distributionVenueTransaction,
      "Distribution Venue",
    );
    const distributionVenueId: string = distributionVenue.id.toString();

    const asset: Asset = await this.sdk.assets.getAsset({
      ticker: ticker,
    });
    const issuance: Issuance = asset.issuance;
    const issueTransaction = await issuance.issue({
      amount: new BigNumber(amount),
    });
    await this.runTransaction(issueTransaction, "Issurance");

    const fromPortfolio =
      fromPortfolioId === "DEFAULT"
        ? await this.identity.portfolios.getPortfolio()
        : await this.identity.portfolios.getPortfolio({
            portfolioId: new BigNumber(fromPortfolioId),
          });

    const targetIdentity: Identity = await this.sdk.identities.getIdentity({
      did: toIdentityId,
    });
    const targetPortfolio: Portfolio =
      toPortfolioId === "DEFAULT"
        ? await targetIdentity.portfolios.getPortfolio()
        : await targetIdentity.portfolios.getPortfolio({
            portfolioId: new BigNumber(toPortfolioId),
          });

    const distributionInstructionTransaction =
      await distributionVenue.addInstruction({
        legs: [
          {
            amount: new BigNumber(amount),
            from: fromPortfolio,
            to: targetPortfolio,
            asset: asset,
          },
        ],
      });

    const distributionInstruction: Instruction = await this.runTransaction(
      distributionInstructionTransaction,
      "Distribution",
    );
    const distributionInstructionId: string =
      distributionInstruction.id.toString(10);

    return {
      did: this.identity.did,
      distributionVenueId,
      distributionInstructionId,
    };
  }

  async isContractValid(contractItemList: IContractItem[]) {
    // this.sdk.g
    if (!this.sdk) {
      return;
    }
    this.$isCheckingContractValid.value = true;
    const allResp = await Promise.all(
      contractItemList.map(
        async ({ fromPortfolio, toPortfolio, ticker, amount }) => {
          const asset = await this.sdk.assets.getAsset({ ticker });
          return {
            fromPortfolio,
            toPortfolio,
            ticker,
            amount,
            resp: await asset.settlements.canTransfer({
              from: fromPortfolio,
              to: toPortfolio,
              amount: new BigNumber(amount),
            }),
          };
        },
      ),
    );
    this.$isCheckingContractValid.value = false;
    console.log("permissions resp", allResp);
    this.$contractValidStatus.value = allResp.map(({ resp, ...others }) => ({
      ...others,
      result: resp.result,
      reason: resp.compliance.requirements
        .filter((r) => !r.complies)
        .map((r) => r.id)
        .join(", "),
    }));
    console.log(
      "this.$contractValidStatus.value",
      this.$contractValidStatus.value,
    );

    return allResp;
  }

  async syncVenueList() {
    this.$isSyncingVenues.value = true;
    // console.log(
    //   "getPendingDistributions",
    //   await this.identity.getPendingDistributions(),
    // );

    const venues = await this.identity.getVenues();
    this.$venues.value = await Promise.all(
      venues.map(async (v) => {
        const detail = await v.details();
        return {
          id: `${v.id}`,
          type: detail.type,
          ownerIdentityId: detail.owner.did,
          description: detail.description,
        };
      }),
    );
    this.$isSyncingVenues.value = false;
  }

  async syncPendingInstructionList() {
    // TODO: use this.identity.getInstructions()
    this.$isSyncingPendingInstructionList.value = true;
    // console.log(
    //   "getPendingDistributions",
    //   await this.identity.getPendingDistributions(),
    // );

    // export type IPendingInstruction = V1Instruction & {
    //   portfolioId: string;
    //   portfolioName: string;
    // };

    const apiInstance = new PortfolioAPI({
      baseUrl: "https://test-polymesh-api.myelseware.io",
    });

    const { data: resp } =
      await apiInstance.api.portfolioServiceGetPendingInstructionListByIdentityId(
        {
          identityId: this.identity.did,
        },
      );

    const pendingInstructionList = [];

    resp.portfolioList.forEach((port) => {
      port.instructionList.forEach((inst) => {
        pendingInstructionList.push({
          ...inst,
          portfolioId: port.id,
          portforliName: port.name,
        });
      });
    });
    this.$pendingInstructionList.value = pendingInstructionList;
    this.$isSyncingPendingInstructionList.value = false;
  }

  async syncPendingInstructionListFromSDK() {
    // TODO: use
    this.$isSyncingPendingInstructionList.value = true;
    // console.log(
    //   "getPendingDistributions",
    //   await this.identity.getPendingDistributions(),
    // );

    // export type IPendingInstruction = V1Instruction & {
    //   portfolioId: string;
    //   portfolioName: string;
    // };

    const resp = await this.identity.getInstructions();
    const pendingInstructionList = Promise.all(
      resp.pending.map(async (v) => {
        return {
          ...(await v.details()),
          ...(await v.getLegs()),
        };
      }),
    );

    // const apiInstance = new PortfolioAPI({
    //   baseUrl: "https://test-polymesh-api.myelseware.io",
    // });

    // const { data: resp } =
    //   await apiInstance.api.portfolioServiceGetPendingInstructionListByIdentityId(
    //     {
    //       identityId: this.identity.did,
    //     },
    //   );

    // const pendingInstructionList = [];

    // resp.portfolioList.forEach((port) => {
    //   port.instructionList.forEach((inst) => {
    //     pendingInstructionList.push({
    //       ...inst,
    //       portfolioId: port.id,
    //       portforliName: port.name,
    //     });
    //   });
    // });
    // this.$pendingInstructionList.value = pendingInstructionList;
    // this.$isSyncingPendingInstructionList.value = false;
  }

  async signInstructionWithId(
    did: string,
    venueId: string,
    instructionId: string,
  ) {
    console.log("trying to", did, venueId, instructionId);
    const owner: Identity = await this.sdk.identities.getIdentity({
      did: did,
    });
    const venue: Venue = (await owner.getVenues()).find((venue: Venue) => {
      return venue.id.isEqualTo(new BigNumber(venueId));
    });
    const instructions = await venue.getInstructions();
    const instructionToSign: Instruction = instructions.pending.find(
      (instruction: Instruction) => {
        return instruction.id.isEqualTo(new BigNumber(instructionId));
      },
    );
    const queue = await instructionToSign.affirm();

    return await this.runTransaction(
      queue,
      `Sign Instruction with [${instructionId}] of Venue [${venueId}]`,
    );
  }

  async searchVenueForOwnerId(ownerId: string) {
    const targetIdentity: Identity = await this.sdk.identities.getIdentity({
      did: ownerId,
    });
    return targetIdentity.getVenues();
  }
}

export const PolymeshService = new PolymeshServiceImpl({
  appName: "Elseware Token Center",
  extensionName: "polywallet", // "elsewarewallet",
  nodeUrl: "wss://testnet-rpc.polymesh.live/",
  // nodeUrl: "wss://mainnet-rpc.polymesh.network/",
});

PolymeshService.init();
