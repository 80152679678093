import { IPortfolioRecord, PolymeshService } from "@/services/polymesh-service";
import { Add } from "@mui/icons-material";
import Button from "@mui/material/Button";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TextField from "@mui/material/TextField";
import { useState, KeyboardEvent, MouseEvent } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useReactive } from "@/hooks/helpers";
import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import PrettyCurrency from "../pretty-currency";
import InputAdornment from "@mui/material/InputAdornment";
import { debounce } from "@mui/material";

const RevokeBetweenPortfolioButton = ({
  fromPortfolioId,
  ticker,
}: {
  fromPortfolioId: string;
  ticker: string;
}) => {
  const [open, setOpen] = useState(false);
  const anchor = "left";
  const toggleDrawer =
    (open_: boolean) => (event: KeyboardEvent | MouseEvent) => {
      setOpen(open_);
    };
  const [targetIdentityId, setTargetIdentityId] = useState("");
  const [targetPortfolioId, setTargetPortfolioId] = useState("");
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [portfolioDict, setPortfolioDict] = useState<{
    [_: string]: IPortfolioRecord;
  }>({});

  const [targetPortfolioList, setTargetPortfolioList] = useState<
    IPortfolioRecord[]
  >([]);

  const fetchPortfoliosForTargetPortfolio = React.useCallback(
    debounce(async (did: string) => {
      setTargetPortfolioList(
        await PolymeshService.searchPortfolioForDid(did, true),
      );
    }, 500),
    [],
  );

  React.useEffect(() => {
    if (targetIdentityId) {
      fetchPortfoliosForTargetPortfolio(targetIdentityId);
    }
  }, [targetIdentityId]);

  React.useEffect(() => {
    setPortfolioDict(
      targetPortfolioList.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: curr }),
        {},
      ),
    );
  }, [targetPortfolioList]);

  const [available, setAvailable] = useState("0");

  React.useEffect(() => {
    const port = portfolioDict[fromPortfolioId];
    if (!port) {
      return;
    }
    const info = port.assets.find((a) => a.assetHumanStr === ticker);
    if (!info) {
      return;
    }
    setAvailable(info.balances.free.toString());
  }, [portfolioDict, fromPortfolioId, ticker]);

  return (
    <>
      <SwipeableDrawer
        anchor={anchor}
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box
          sx={{
            minWidth: "500px",
            width: "33vw",
            padding: "50px",
            boxSizing: "border-box",
          }}
        >
          <h1>Revoke </h1>
          <h3 style={{ textAlign: "center" }}>{ticker}</h3>
          <h2>from</h2>
          <Box
            sx={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
          >
            <TextField
              required
              fullWidth
              id="destination"
              label="Destination"
              onChange={(e) =>
                setTargetIdentityId(e.target.value as unknown as string)
              }
            />
          </Box>
          <h2>&apos;s</h2>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="portfolio-select-label">Portfolio</InputLabel>
              <Select
                required
                labelId="portfolio-select-label"
                id="portfolio-select"
                label="Portfolio"
                value={targetPortfolioId}
                onChange={(e) => setTargetPortfolioId(e.target.value as string)}
              >
                {targetPortfolioList.map((p) => (
                  <MenuItem value={p.id} key={p.id}>
                    {p.name}({p.id})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <h2>with</h2>
          <Box
            sx={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
          >
            <TextField
              required
              fullWidth
              id="amount"
              label="Amount"
              type="number"
              onChange={(e) => setAmount(e.target.value as unknown as number)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <span style={{ margin: "0 3px" }}>/</span>
                    <PrettyCurrency value={parseFloat(available)} currency="" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <h2 style={{ marginBottom: "50px" }}>unit(s)</h2>
          {/* <Box
            sx={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
          >
            <TextField
              fullWidth
              id="description"
              label="Description"
              onChange={(e) =>
                setDescription(e.target.value as unknown as string)
              }
            />
          </Box> */}

          <Button
            disabled={!!!amount || !!!targetPortfolioId}
            fullWidth
            variant="contained"
            onClick={async () => {
              try {
                setLoading(true);
                await PolymeshService.revokeAsset({
                  ticker,
                  fromIdentityId: targetIdentityId,
                  fromPortfolioId: targetPortfolioId,
                  amount: `${amount}`,
                });
                setLoading(false);
              } catch (e) {
                console.error(e);
                setLoading(false);
              }
              setOpen(false);
              PolymeshService.getPortfolios();
            }}
          >
            {loading && (
              <CircularProgress
                size="14px"
                sx={{ color: "white", marginRight: "10px" }}
              />
            )}
            Revoke
          </Button>
        </Box>
      </SwipeableDrawer>
      <Button size="small" onClick={() => setOpen(true)}>
        Revoke
      </Button>
    </>
  );
};

export default RevokeBetweenPortfolioButton;
