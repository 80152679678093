import { copyToClipboard, formatAddress } from "@/utils";
import { CopyAllRounded, Link } from "@mui/icons-material";
import Box from "@mui/material/Box";

const AddressViewer = ({
  address,
  addressType = "block",
}: {
  address?: string;
  addressType?: "block" | "extrinsic";
}) => {
  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <span
        style={{
          background: "#274D8D",
          color: "#f3a063",
          padding: "0 3px",
          borderRadius: "3px",
        }}
      >
        {formatAddress(address)}
      </span>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "10px",
          color: "#274D8D",
          fontSize: "12px",
        }}
      >
        <CopyAllRounded
          sx={{ height: "14px", width: "14px", cursor: "pointer" }}
          onClick={() => copyToClipboard(address)}
        />
        <Link
          sx={{
            height: "14px",
            width: "14px",
            cursor: "pointer",
            marginLeft: "5px",
          }}
          onClick={() => {
            window.open(
              `https://polymesh-testnet.subscan.io/${addressType}/${address}`,
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default AddressViewer;
