import { FC, useEffect, useState, CSSProperties } from "react";
import numeral from "numeral";

const PrettyCurrency: FC<{
  value: number;
  currency?: string;
  toFixed?: number;
  style?: CSSProperties;
  pnl?: boolean;
}> = ({ value, currency = "$", style, toFixed = 0, pnl = false }) => {
  const [innerValue, setInnerValue] = useState("");
  useEffect(() => {
    setInnerValue(numeral(value).format(`0,0[.]00`));
  }, [value]);
  return (
    <div style={style}>
      {pnl ? (
        value > 0 ? (
          <span style={{ color: "green" }}>
            {currency}
            {numeral(value).format(`0,0[.]00`)}
          </span>
        ) : (
          <span style={{ color: "red" }}>
            ({currency}
            {numeral(Math.abs(value)).format(`0,0[.]00`)})
          </span>
        )
      ) : (
        <span>
          {currency}
          {numeral(value).format(`0,0[.]00`)}
        </span>
      )}
    </div>
  );
};

export default PrettyCurrency;
