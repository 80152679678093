import PrettyCurrency from "@/components/pretty-currency";
import styled from "@emotion/styled";
import { Help } from "@mui/icons-material";
import blue from "@mui/material/colors/blue";
import Grid from "@mui/material/Grid";
import { spacing } from "@mui/system";
import { Card, CardContent, UpDownDiv } from "@/utils";
import { FC } from "react";
import "./index.scss";
import Box from "@mui/material/Box";

const TokenCurrency: FC<{
  value: number;
  symbol: string;
  style?: React.CSSProperties;
}> = ({ value, symbol, style }) => {
  return (
    <div style={{ display: "flex", alignItems: "end", ...style }}>
      <PrettyCurrency
        currency=""
        value={value}
        style={{ fontSize: "16px", fontWeight: 600 }}
      />
      <div
        style={{
          fontSize: "14px",
          marginLeft: "5px",
          opacity: 0.8,
          fontWeight: 800,
        }}
      >
        {symbol}
      </div>
    </div>
  );
};

const TokenBalanceCard: FC<{
  tokenLogo: React.ReactNode;
  balance: number;
  tokenSymbol: string;
  available: number;
  unAvailable: number;
  spaceBetween?: boolean;
  balanceTitle?: string;
  rightHeaderComponent?: React.ReactNode;
}> = ({
  tokenLogo,
  tokenSymbol,
  balance,
  available,
  unAvailable,
  spaceBetween,
  balanceTitle = "Your Total Balance",
  rightHeaderComponent,
}) => {
  return (
    <Card>
      <CardContent>
        <div className="token-card" style={{ height: "108px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box className="token-card__header">
              <div className="token-card__logo">{tokenLogo}</div>
              <div className="token-card__balance">
                <div
                  className="token-card__balance__title"
                  style={{ fontSize: "12px", fontWeight: 700, opacity: 0.5 }}
                >
                  {balanceTitle}
                </div>
                <div className="token-card__balance__detail">
                  <TokenCurrency value={balance} symbol={tokenSymbol} />
                </div>
              </div>
            </Box>
            {rightHeaderComponent || null}
          </div>
          <div
            className="token-card__footer"
            style={{
              ...(spaceBetween && {
                display: "flex",
                justifyContent: "space-between",
              }),
            }}
          >
            <Grid container spacing={6}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl>
                <UpDownDiv
                  header={
                    <TokenCurrency
                      value={available}
                      symbol={tokenSymbol}
                      style={{ color: blue[300] }}
                    />
                  }
                  footer={<span>Available</span>}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl>
                <UpDownDiv
                  header={
                    <TokenCurrency value={unAvailable} symbol={tokenSymbol} />
                  }
                  footer={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      Unavailable
                      <Help
                        style={{
                          height: "14px",
                          width: "14px",
                          marginLeft: "5px",
                        }}
                      />
                    </span>
                  }
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default styled(TokenBalanceCard)(spacing);
