// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from "react";
import styled from "@emotion/styled";
import MuiCard from "@mui/material/Card";
import MuiCardContent from "@mui/material/CardContent";

export const formatAddress = (raw?: string) => {
  return raw ? `${raw.slice(0, 8)}...${raw.slice(-8)}` : "-";
};

export const Card = styled(MuiCard)<{ illustration?: string }>`
  position: relative;
  margin-bottom: 6px;
`;

export const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    margin-bottom: 6px;
  }
`;

export const UpDownDiv: React.FC<{
  header: React.ReactNode;
  footer: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ header, footer, style }) => {
  return (
    <div style={style}>
      <div style={{ whiteSpace: "nowrap" }}>{header}</div>
      <div style={{ opacity: 0.7 }}>{footer}</div>
    </div>
  );
};

export function copyToClipboard(text: string) {
  if (document.execCommand) {
    const input = document.createElement("input");
    input.setAttribute("value", text);
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  } else {
    navigator.clipboard.writeText(text).then(() => {
      // Alert the user that the action took place.
      // Nobody likes hidden stuff being done under the hood!
      console.log("ok");
    });
  }
}
