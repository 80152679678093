import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { Overview } from "@/page/overview";
import { TokenDetail } from "@/page/token-detail";
import TokenFactory from "./page/token-factory";
import { MainLayout } from "./layout/main";
import { Transaction } from "./page/transaction";
import { Venue } from "./page/venue";
import { Distribution } from "./page/distribution";
import { PortfolioSearch } from "./page/portfolio-search";
import { PendingInstructionList } from "./page/pending-instruction-list";
import { Contract } from "./page/contract";

export const routes: RouteObject[] = [
  {
    path: "/main",
    element: <MainLayout />,
    children: [
      {
        path: "/main/overview",
        element: <Overview />,
      },
      {
        path: "/main/token",
        element: <TokenDetail />,
      },
      {
        path: "/main/transaction",
        element: <Transaction />,
      },
      {
        path: "/main/token-factory",
        element: <TokenFactory />,
      },
      {
        path: "/main/Contract",
        element: <Contract />,
      },
      {
        path: "/main/venue",
        element: <Venue />,
      },
      {
        path: "/main/distribution",
        element: <Distribution />,
      },
      {
        path: "/main/search",
        element: <PortfolioSearch />,
      },
      {
        path: "/main/pending-instruction-list",
        element: <PendingInstructionList />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate replace={true} to="/main/overview" />,
  },
];
