import TokenAccountCard from "@/components/token-account-card";
import TokenBalanceCard from "@/components/token-balance-card";
import { useReactive } from "@/hooks/helpers";
import { PolymeshService } from "@/services/polymesh-service";
import { formatAddress } from "@/utils";
import Box from "@mui/material/Box";
import { useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Logo from "@/assets/logo.png";
import CreateNewButton from "@/components/CreateNewButton";
import MoveBetweenPortfolioButton from "@/components/MoveBetweenPortfolioButton";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import DistributeBetweenPortfolioButton from "@/components/DistributeBetweenPortfolioButton";
import TransferBetweenPortfolioButton from "@/components/TransferBetweenPortfolioButton";
import RevokeBetweenPortfolioButton from "@/components/RevokeBetweenPortfolioButton";

export const Overview = () => {
  const balance = useReactive(PolymeshService.$balance);
  const account = useReactive(PolymeshService.$account);
  const identity = useReactive(PolymeshService.$identity);
  const assets = useReactive(PolymeshService.$assets);
  const portfolios = useReactive(PolymeshService.$portfolios);
  const appReady = useReactive(PolymeshService.$ready);
  const assetExtraInfo = useReactive(PolymeshService.$assetExtraInfo);
  const isLoadingPortfolios = useReactive(PolymeshService.$isLoadingPortfolios);

  console.log("balance", balance);
  useEffect(() => {
    if (appReady) PolymeshService.getPortfolios();
  }, [appReady]);
  return (
    <Box style={{ padding: "120px" }}>
      <h1>Overview</h1>
      <Box style={{ display: "flex" }}>
        <Box style={{ width: "400px", marginRight: "50px" }}>
          <TokenAccountCard
            accountId={formatAddress(identity?.did)}
            expiredOn="Never"
            verifiedBy="0x000...01"
          />
        </Box>
        <Box style={{ width: "400px" }}>
          <TokenBalanceCard
            tokenLogo={
              <img src={Logo} style={{ width: "40px", height: "40px" }} />
            }
            tokenSymbol={"POLYX"}
            balance={balance?.total.toNumber() || 0}
            available={balance?.free.toNumber() || 0}
            unAvailable={balance?.locked.toNumber() || 0}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <h1>Portfolios</h1>
        <Box sx={{ marginLeft: "20px" }}>
          <CreateNewButton />
        </Box>
      </Box>
      {isLoadingPortfolios && (
        <CircularProgress size="22px" sx={{ marginRight: "10px" }} />
      )}
      <Box>
        {portfolios.map((port, idx) => {
          return (
            <Box key={`${idx}`}>
              <h2>
                {port.name} ({port.id})
              </h2>
              <Grid container spacing={2}>
                {port.assets.map((asset, assetIndex) => {
                  return (
                    <Grid
                      item
                      key={`${assetIndex}`}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={3}
                      onClick={() => {
                        asset.fetchDetailFunc();
                      }}
                    >
                      <Box style={{ minWidth: "450px", maxWidth: "450px" }}>
                        <TokenBalanceCard
                          rightHeaderComponent={
                            <Box>
                              {/* <Button size="small">Transfer</Button> */}
                              <Box>
                                <MoveBetweenPortfolioButton
                                  from={port.id}
                                  asset={asset.assetHumanStr}
                                />
                                <DistributeBetweenPortfolioButton
                                  fromPortfolioId={port.id}
                                  ticker={asset.assetHumanStr}
                                />
                                <TransferBetweenPortfolioButton
                                  fromPortfolioId={port.id}
                                  ticker={asset.assetHumanStr}
                                />
                                <RevokeBetweenPortfolioButton
                                  fromPortfolioId={port.id}
                                  ticker={asset.assetHumanStr}
                                />
                              </Box>
                            </Box>
                          }
                          tokenLogo={
                            <Box
                              style={{
                                background: "#274D8D",
                                color: "#F3A063",
                                padding: "10px",
                                width: "20px",
                                height: "20px",
                                textAlign: "center",
                                opacity: 0.8,
                                borderRadius: "50%",
                                fontWeight: 600,
                              }}
                            >
                              {asset.assetHumanStr.startsWith("ELSEO")
                                ? asset.assetHumanStr
                                    .split("ELSEO")[1][0]
                                    .substring(0, 3)
                                : asset.assetHumanStr[0]}
                            </Box>
                          }
                          tokenSymbol={asset.assetHumanStr}
                          balance={asset.balances?.total.toNumber() || 0}
                          available={asset.balances?.free.toNumber() || 0}
                          unAvailable={asset.balances?.locked.toNumber() || 0}
                        />
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          );
        })}
      </Box>
      {/* {JSON.stringify(portfolios)} */}
    </Box>
  );
};
