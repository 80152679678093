import {
  PolymeshService,
  PolymeshServiceImpl,
} from "@/services/polymesh-service";
import { watch } from "@vue-reactivity/watch";
import { isReactive, isRef, Ref, UnwrapNestedRefs } from "@vue/reactivity";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "@mui/material";
import { Account, Identity } from "@polymeshassociation/polymesh-sdk/types";

export function useForceUpdate() {
  const [, update] = useState({});
  return useCallback(() => {
    update({});
  }, []);
}

function retrieveValue<T>(
  r: Ref<T> | UnwrapNestedRefs<T> | T,
  defaultValue?: T,
): T {
  if (isRef(r)) {
    return r.value;
  }
  if (isReactive(r)) {
    return r as T;
  }
  return defaultValue ? defaultValue : (r as T);
}

export function useReactive<T>(r: Ref<T> | UnwrapNestedRefs<T> | T): T {
  const [dummy] = useState(retrieveValue(r));
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    if (isRef(r)) {
      return watch(r as Ref<T>, () => forceUpdate(), { deep: true });
    } else if (isReactive(r)) {
      return watch(
        () => r as UnwrapNestedRefs<T>,
        () => forceUpdate(),
        { deep: true },
      );
    }
  }, [r]);
  return retrieveValue(r, dummy);
}

export function useIdentityInfoByAccount(parameters: {
  initAddress?: string;
  containAsset?: boolean;
}) {
  const [address, setAddress] = useState<string>(parameters.initAddress);
  // const [identityId, setIdentityId] = useState<string>();
  const [identity, setIdentity] = useState<Identity>();
  const [account, setAccount] = useState<Account>();

  const [portfolioList, setPortfolioList] = useState<
    Awaited<ReturnType<PolymeshServiceImpl["searchPortfolioForDid"]>>
  >([]);

  const fetchPortfoliosForTargetPortfolio = React.useCallback(
    debounce(async (did: string) => {
      setPortfolioList(
        await PolymeshService.searchPortfolioForDid(
          did,
          parameters.containAsset,
        ),
      );
    }, 500),
    [parameters],
  );

  // const setIdentityIdByAddressOrIdentityId = React.useCallback(
  //   debounce(async (inputStr: string) => {
  //     const identityId = await PolymeshService.ensureStrAsIdentityId(inputStr);
  //     setIdentityId(identityId);
  //   }, 500),
  //   [],
  // );

  const updateIdentityAndAccountByAddress = React.useCallback(
    debounce(async (addressStr: string) => {
      const { identity, account } =
        await PolymeshService.getAccountAndIdentityByAddress(addressStr);
      setIdentity(identity);
      setAccount(account);
    }, 500),
    [],
  );

  React.useEffect(() => {
    if (identity && identity.did) {
      fetchPortfoliosForTargetPortfolio(identity.did);
    }
  }, [identity]);

  React.useEffect(() => {
    if (address) {
      updateIdentityAndAccountByAddress(address);
    }
  }, [address]);

  return {
    identityId: identity?.did,
    portfolioList,
    address,
    account,
    setAddress,
  };
}
