import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  ITransactionHistoryRecord,
  PolymeshService,
} from "@/services/polymesh-service";
import { useReactive } from "@/hooks/helpers";
import AddressViewer from "@/components/AddressViewer";
import CircularProgress from "@mui/material/CircularProgress";
import { Refresh } from "@mui/icons-material";
import Button from "@mui/material/Button";

function Row(props: { row: ITransactionHistoryRecord }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <AddressViewer address={row.blockHash} />
        </TableCell>
        {/* <TableCell align="left">{row.blockNumber}</TableCell> */}
        {/* <TableCell align="left">{row.extrinsicHash}</TableCell> */}
        {/* <TableCell align="left">{row.extrinsicIdx}</TableCell> */}
        {/* <TableCell align="left">{row.nonce}</TableCell> */}
        {/* <TableCell align="left">{row.specVersionId}</TableCell> */}
        <TableCell align="left">{row.txTag}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Params
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(row.params || []).map((param) => (
                    <TableRow key={param.name}>
                      <TableCell component="th" scope="row">
                        {param.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {param.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const Transaction = () => {
  const transactionHistory = useReactive(PolymeshService.$transactionHistory);
  const isSyncingTransactionHistory = useReactive(
    PolymeshService.$isSyncingransactionHistory,
  );
  React.useEffect(() => {
    PolymeshService.syncTransactionHistory();
  }, []);
  console.log("transactionHistory", transactionHistory);
  return (
    <Box style={{ maxWidth: "800px", margin: "20px auto" }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Button
                  disabled={isSyncingTransactionHistory}
                  sx={{ width: "120px" }}
                  size="small"
                  variant="contained"
                  onClick={async () => {
                    await PolymeshService.syncTransactionHistory();
                  }}
                >
                  {isSyncingTransactionHistory && (
                    <CircularProgress
                      size="14px"
                      sx={{ color: "white", marginRight: "10px" }}
                    />
                  )}
                  Refresh
                </Button>
              </TableCell>
              <TableCell>Block Hash</TableCell>
              {/* <TableCell align="left">Block Number</TableCell> */}
              {/* <TableCell align="left">Extrinsic Hash</TableCell>
            <TableCell align="left">Extrinsic Index</TableCell> */}
              {/* <TableCell align="left">Nonce</TableCell> */}
              {/* <TableCell align="left">Spec Version ID</TableCell> */}
              <TableCell align="left">Tag</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(transactionHistory || []).map((row, idx) => (
              <Row key={`${idx}`} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

// export const Transaction = () => {
//   useEffect(() => {
//     PolymeshService.syncTransactionHistory();
//   }, []);
//   return (
//     <Box>
//       Security token details
//       <Button>Edit token details</Button>
//       <Box>
//         <Box>Your ticker symbol</Box>
//         <Box>ELSEOHLOWD</Box>
//       </Box>
//       <Box>
//         <Box>icon</Box>
//         <Box>
//           <Box>Total supplu</Box>
//           <Box>100000 ELSEOHLOWD</Box>
//         </Box>
//       </Box>
//       <Box>
//         {[
//           {
//             field: "Security token name",
//             value: "ELSEOHLOWD",
//           },
//           {
//             field: "Security type",
//             value: "Equity Common",
//           },
//           {
//             field: "Divisibility of token",
//             value: "Equity Common",
//           },
//         ].map(({ field, value }) => {
//           return (
//             <Box key={field}>
//               <Box>{field}</Box>
//               <Box>{value}</Box>
//             </Box>
//           );
//         })}
//       </Box>
//     </Box>
//   );
// };
