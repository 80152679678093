import AddressViewer from "@/components/AddressViewer";
import { useReactive } from "@/hooks/helpers";
import {
  IPendingInstruction,
  IPendingVenue,
  PolymeshService,
} from "@/services/polymesh-service";
import { Collapse } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import Typography from "@mui/material/Typography";

function Row(props: { row: IPendingInstruction; onRefresh: () => void }) {
  const { row: instruction, onRefresh } = props;

  const [isSigning, setIsSigning] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="left">{instruction.id}</TableCell>
        <TableCell align="left">{instruction.venueId}</TableCell>
        <TableCell component="th" scope="row">
          <AddressViewer address={instruction.venueOwnerId} />
        </TableCell>
        <TableCell align="left">{instruction.venueType}</TableCell>
        <TableCell align="left">{instruction.venueDescription}</TableCell>
        <TableCell align="center">
          <Button
            variant="contained"
            disabled={isSigning}
            onClick={async () => {
              setIsSigning(true);
              await PolymeshService.signInstructionWithId(
                instruction.venueOwnerId,
                instruction.venueId,
                instruction.id,
              );
              onRefresh();
              setIsSigning(false);
            }}
          >
            {isSigning && (
              <CircularProgress
                size="14px"
                sx={{ color: "white", marginRight: "10px" }}
              />
            )}
            sign
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Leg Detail
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>From ID</TableCell>
                    <TableCell>To ID</TableCell>
                    <TableCell>Asset ID</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(instruction.legList || []).map((leg) => (
                    <TableRow key={leg.id}>
                      <TableCell component="th" scope="row">
                        {leg.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {leg.fromId}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {leg.toId}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {leg.assetId}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {leg.amount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export const PendingInstructionList = () => {
  const pendingInstructionList = useReactive(
    PolymeshService.$pendingInstructionList,
  );
  const isSyncingPendingInstructionList = useReactive(
    PolymeshService.$isSyncingPendingInstructionList,
  );
  React.useEffect(() => {
    PolymeshService.syncPendingInstructionList();
  }, []);
  return (
    <Box style={{ maxWidth: "1600px", margin: "20px auto" }}>
      <Button
        disabled={isSyncingPendingInstructionList}
        sx={{ width: "120px" }}
        size="small"
        variant="contained"
        onClick={async () => {
          await PolymeshService.syncPendingInstructionList();
        }}
      >
        {isSyncingPendingInstructionList && (
          <CircularProgress
            size="14px"
            sx={{ color: "white", marginRight: "10px" }}
          />
        )}
        Refresh
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Instruction Id</TableCell>
              <TableCell align="left">Venue ID</TableCell>
              <TableCell align="left">Venue Owner DID</TableCell>
              <TableCell align="left">Venue Type</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(pendingInstructionList || []).map((row, idx) => (
              <Row
                key={`${idx}`}
                row={row}
                onRefresh={() => PolymeshService.syncPendingInstructionList()}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
