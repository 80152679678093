import { ITransferItem, PolymeshService } from "@/services/polymesh-service";
import {
  debounce,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { BigNumber } from "@polymeshassociation/polymesh-sdk";
import { Instruction, Venue } from "@polymeshassociation/polymesh-sdk/types";
import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useIdentityInfoByAccount } from "@/hooks/helpers";
import InputAdornment from "@mui/material/InputAdornment";
import PrettyCurrency from "@/components/pretty-currency";
import { AccountManagement } from "@polymeshassociation/polymesh-sdk/api/client/AccountManagement";
import { useReactive } from "@/hooks/helpers";

interface ITradeItem {
  ticker: string;
  amount: string;
}

const TradeItemListEditor = ({
  tradeItemList,
  onChangeTradeItemList,
  identityInfo,
}: {
  tradeItemList: ITradeItem[];
  onChangeTradeItemList: (v: ITradeItem[]) => void;
  identityInfo: ReturnType<typeof useIdentityInfoByAccount>;
}) => {
  const [internalItemList, setInternalItemList] = React.useState(tradeItemList);

  const handleItemChange = (
    index: number,
    field: keyof ITradeItem,
    value: string,
  ) => {
    const newItems = [...internalItemList];
    newItems[index][field] = value;
    setInternalItemList(newItems);
    onChangeTradeItemList(newItems);
  };

  const handleAddItem = () => {
    setInternalItemList([...internalItemList, { ticker: "", amount: "" }]);
  };

  const handleDeleteItem = (index: number) => {
    const newItems = [...internalItemList];
    newItems.splice(index, 1);
    setInternalItemList(newItems);
    onChangeTradeItemList(newItems);
  };

  const { identityId, address, setAddress, portfolioList } = identityInfo;

  console.log("portfolioList", portfolioList);

  const thePortfolio = portfolioList[0];
  const assetDict = thePortfolio?.assetDict;

  return (
    <Box sx={{ overflow: "auto", width: "100%" }}>
      <Box sx={{ marginBottom: "20px", display: "flex", alignItems: "center" }}>
        <TextField
          required
          fullWidth
          id="address"
          label="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value as unknown as string)}
          helperText={`DID: ${identityId}`}
          variant="standard"
        />
        {/* <Box sx={{ margin: "0 10px" }}>&rarr;</Box> */}
      </Box>
      {/* <Box>IdentityID: {identityId}</Box> */}

      <Box sx={{ textAlign: "center", marginBottom: "15px" }}>
        <Typography variant="h6">
          Payment Obligations <br />
          (the other party&lsquo;s Payment Receipts)
        </Typography>
      </Box>

      {undefined === thePortfolio || undefined === assetDict ? (
        <Box>Please Provide an Address</Box>
      ) : (
        internalItemList.map((item, index) => (
          <Box
            key={index}
            sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
          >
            <FormControl fullWidth sx={{ marginRight: "15px" }}>
              <InputLabel id="asset-id-select-label">Ticker</InputLabel>
              <Select
                labelId="asset-id-select-label"
                id="asset-id-select"
                value={item.ticker}
                onChange={(e) =>
                  handleItemChange(index, "ticker", e.target.value)
                }
                sx={{ marginPartyB: 2 }}
                label="Ticker"
              >
                {thePortfolio.assets.map((v) => {
                  return (
                    <MenuItem value={v.assetHumanStr} key={v.assetHumanStr}>
                      {v.assetHumanStr}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              type="number"
              placeholder="Amount"
              value={item.amount}
              onChange={(e) =>
                handleItemChange(index, "amount", e.target.value)
              }
              sx={{ marginPartyB: 2, minWidth: "150px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <span style={{ margin: "0 3px" }}>/</span>
                    <PrettyCurrency
                      value={parseFloat(
                        assetDict[item.ticker]?.balances?.free?.toString() ||
                          "0",
                      )}
                      currency=""
                    />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton onClick={() => handleDeleteItem(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))
      )}
      <Button
        sx={{ width: "100%" }}
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleAddItem}
      >
        Add new Item
      </Button>
    </Box>
  );
};

export const Contract = () => {
  const [partyAToPartyBTradeItemList, setPartyAToPartyBTradeItemList] =
    React.useState<ITradeItem[]>([]);
  const [partyBToPartyATradeItemList, setPartyBToPartyATradeItemList] =
    React.useState<ITradeItem[]>([]);

  const isCheckingContractValid = useReactive(
    PolymeshService.$isCheckingContractValid,
  );

  const partyATradeIdentityInfo = useIdentityInfoByAccount({
    initAddress: "5H6EUFmajs8S87X2DzR9eiwLdwHGVHh4EhiXaA1zKZBkF6wG", // invertor-1
    containAsset: true,
  });

  const partyBTradeIdentityInfo = useIdentityInfoByAccount({
    initAddress: "5FTsLyWpvvb87vxgAnr766Z1w2c5nLud2uocLUjA1HhA3hCR", // invertor-2
    containAsset: true,
  });

  const handleChangePartyAToPartyBTradeItemList = (items: ITradeItem[]) => {
    setPartyAToPartyBTradeItemList(items);
    // handle change here
  };

  const handleChangePartyBToPartyATradeItemList = (items: ITradeItem[]) => {
    setPartyBToPartyATradeItemList(items);
    // handle change here
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "95%",
        width: "100%",
        flex: 1,
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflow: "auto",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "flex-start",
            height: "100%",
            width: "100%",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              flex: 1,
              height: "100%",
              overflow: "auto",
              maxWidth: "800px",
            }}
          >
            <Typography variant="h3">Party A</Typography>
            <TradeItemListEditor
              tradeItemList={partyAToPartyBTradeItemList}
              onChangeTradeItemList={handleChangePartyAToPartyBTradeItemList}
              identityInfo={partyATradeIdentityInfo}
            />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              flex: 1,
              overflow: "auto",
              height: "100%",
              maxWidth: "800px",
            }}
          >
            <Typography variant="h3">Party B</Typography>
            <TradeItemListEditor
              tradeItemList={partyBToPartyATradeItemList}
              onChangeTradeItemList={handleChangePartyBToPartyATradeItemList}
              identityInfo={partyBTradeIdentityInfo}
            />
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        fullWidth
        sx={{ maxWidth: "1000px" }}
        disabled={isCheckingContractValid}
        onClick={async () => {
          const [partyAPortfolio, partyBPortfolio] = await Promise.all([
            PolymeshService.getPortolioByIdentityIdAndId(
              partyATradeIdentityInfo.identityId,
              partyATradeIdentityInfo?.portfolioList[0]?.id,
            ),
            PolymeshService.getPortolioByIdentityIdAndId(
              partyBTradeIdentityInfo.identityId,
              partyBTradeIdentityInfo?.portfolioList[0]?.id,
            ),
          ]);

          // const [partyAHasPermission, partyBHasPermission] = await Promise.all([
          //   // identityHasAssetListPermission;
          //   PolymeshService.identityHasAssetListPermission(
          //     partyATradeIdentityInfo.address,
          //     partyBToPartyATradeItemList.map((v) => v.ticker),
          //   ),
          //   PolymeshService.identityHasAssetListPermission(
          //     partyBTradeIdentityInfo.address,
          //     partyAToPartyBTradeItemList.map((v) => v.ticker),
          //   ),
          // ]);
          // console.log(partyAHasPermission, partyBHasPermission);
          //   const partyAPortfolio =
          //     await PolymeshService.getPortolioByIdentityIdAndId(
          //       partyATradeIdentityInfo.identityId,
          //       partyATradeIdentityInfo?.portfolioList[0]?.id,
          //     );
          //   const partyBPortfolio =
          //     await PolymeshService.getPortolioByIdentityIdAndId(
          //       partyBTradeIdentityInfo.identityId,
          //       partyBTradeIdentityInfo?.portfolioList[0]?.id,
          //     );
          if (!partyAPortfolio || !partyBPortfolio) {
            return;
          }

          //   console.log("partyAPortfolio", partyAPortfolio);
          //   console.log("partyBPortfolio", partyBPortfolio);
          //   console.log("contractItemList", [
          //     ...partyAToPartyBTradeItemList.map((v) => ({
          //       ...v,
          //       fromPortfolio: partyAPortfolio,
          //       toPortfolio: partyBPortfolio,
          //     })),
          //     ...partyBToPartyATradeItemList.map((v) => ({
          //       ...v,
          //       fromPortfolio: partyBPortfolio,
          //       toPortfolio: partyAPortfolio,
          //     })),
          //   ]);

          const contractItemList = [
            ...partyAToPartyBTradeItemList.map((v) => ({
              ...v,
              fromPortfolio: partyAPortfolio,
              toPortfolio: partyBPortfolio,
            })),
            ...partyBToPartyATradeItemList.map((v) => ({
              ...v,
              fromPortfolio: partyBPortfolio,
              toPortfolio: partyAPortfolio,
            })),
          ];

          // await PolymeshService.isContractValid(contractItemList);

          await PolymeshService.createContract({
            description: "Create by polymesh",
            contractItemList,
          });
        }}
      >
        {isCheckingContractValid && (
          <CircularProgress
            size="14px"
            sx={{ color: "white", marginRight: "10px" }}
          />
        )}
        Create a contract
      </Button>
    </Box>
  );
};
